











































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { GameModule, UserModule } from '@/store/modules'
import { Shop } from '@/api'
import { Toast } from '@/mixins'

@Component
export default class ItemModal extends Mixins(Toast) {
  @GameModule.State('characters') charactersFromAPI
  @UserModule.Action getUser
  @UserModule.State currentUser

  error = false
  modal = false
  quantity = 1
  characterToSend: Nullable<number> = null
  item = {} as Pack
  characters: any[] = []

  @Watch('quantity')
  onQuantityChange(newQty, oldQty) {
    if ((!newQty || !oldQty) || parseInt(newQty) <= 0) this.quantity = 1

    if (parseInt(newQty) > 50) this.quantity = 50
  }

  mounted() {
    const characters = this.charactersFromAPI.map(c => {
      return {
        value: c.id,
        text: c.name
      }
    })

    characters.unshift({
      value: null,
      text: 'Select character'
    })

    this.characters = characters

    this.$root.$on('openShopItemModal', (item: Pack) => {
      this.modal = true
      this.item = item
    })
  }

  get characterNameById() {
    return this.characters.find(e => e.value === this.characterToSend)!.text
  }

  get price() {
    return (this.item as Pack).price * this.quantity
  }

  async confirm() {
    if (!this.characterToSend) {
      this.error = true
      return
    }

    const confirmationMessage = `
      You are going to buy x${this.quantity} ${this.item.name} for ${this.price} OC.
      Are you sure?
    `

    const confirmed = await this.$bvModal.msgBoxConfirm(confirmationMessage, {
      title: 'Confirm Purchase',
      size: 'sm',
      okVariant: 'outline-primary',
      okTitle: 'Confirm',
      cancelTitle: 'Cancel',
      cancelVariant: 'outline-secondary',
      hideHeaderClose: false,
      centered: true,
      noCloseOnBackdrop: true,
      noCloseOnEsc: true
    })

    if (!confirmed) return

    const itemBought = await Shop.buyItem({
      characterId: this.characterToSend,
      id: this.item.id,
      quantity: this.quantity
    })

    if (itemBought) {
      await this.getUser()
      this.showSuccessfullyBoughtItemToast()
      this.resetData()
    }
  }

  showSuccessfullyBoughtItemToast() {
    this.modal = false

    this.showSuccessToast({
      title: 'Thanks for your purchase',
      message: `
        Successfully bought ${this.item.name} x${this.quantity} for ${this.price} OC.
        Items have been sent to ${this.characterNameById}
      `
    })
  }

  resetData() {
    this.quantity = 1
    this.characterToSend = null
  }
}
